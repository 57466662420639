import { DateTime } from "luxon";
/**
 * checks if this media is live.
 * validated by checking the starttime and endtime
 * if it'startTime between DateTime.now()
 *
 *
 * usage:
 * <UiTagLive v-if="useIsLive(media)" />
 */
export default function (media) {
  if (!media) return false;

  const { startDate, endDate } = media;
  const now = DateTime.now();

  // // Check if start and end times are valid Luxon objects
  // const isInvalid =
  //   !DateTime.isDateTime(startDate) || !DateTime.isDateTime(endDate);

  // // Check if both start and end times are in the past
  // const isInPast = startDate < now && endDate < now;

  // // Check if both start and end times are in the future
  // const isInFuture = startDate > now && endDate > now;

  // if (isInvalid || isInPast || isInFuture) {
  //   return false;
  // }

  // // Now, at least one of the times must be in the present
  // result = now > startDate && now < endDate;

  // applying the same logic as in EPG:

  const isNow = now > startDate && now < endDate;

  /**
   * new logic: if media.extrafields contain the object
   * with name telecast_type
   * and value Live,
   *
   * this is a live program
   */
  const isTelecastLive =
    media.extrafields
      ?.find((e) => e.name == "telecast_type")
      ?.value?.toUpperCase() == "LIVE";

  return isNow && isTelecastLive;
}
